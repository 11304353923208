@charset "UTF-8";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F4F3F1;
}

@font-face {
  font-family: Averta-CY;
  src: url('./assets/fonts/AvertaCY-Black.ttf') format('truetype'),
  url("./assets/fonts/Averta Black.woff") format('woff'),
  url("./assets/fonts/Averta Cyrillic Black.otf") format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: Averta-CY;
  src: url('./assets/fonts/AvertaCY-Light.ttf') format('truetype'),
  url("./assets/fonts/Averta Light.woff") format('woff'),
  url("./assets/fonts/Averta Cyrillic Light.otf") format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: Averta-CY;
  src: url('./assets/fonts/AvertaCY-Semibold.ttf') format('truetype'),
  url("./assets/fonts/Averta Semibold.woff") format('woff'),
  url("./assets/fonts/Averta Cyrillic SemiBold.otf") format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: Averta-CY;
  src: url('./assets/fonts/AvertaCY-Regular.ttf') format('truetype'),
  url("./assets/fonts/Averta.woff") format('woff'),
  url("./assets/fonts/Averta Cyrillic Regular.otf") format('opentype');
  font-weight: 400;
}




@import "utils/variables";


.body--overlay {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;

  &:focus {
    color: inherit;
  }
}

.content {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.page {
  flex-grow: 1;
  margin-top: 50px;

  &--home{
    margin-top: 0;
  }

  @media (min-width: $width-md) {
    margin: 100px 0 0;
  }

  &__content {
    position: relative;
    z-index: 2;
  }
}

.page-subtitle{
  font-family: $font-main;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #1A1A1A;
  text-align: center;
  margin: 15px 0 30px;
}

.page-inner {
  display: flex;
  padding: 25px 0;

  &__box {
    flex-grow: 1;
    &:nth-child(2) {
      display: none;

      @media (min-width: $width-lg) {
        display: block;
        max-width: 367px;
        width: 100%;
        margin-left: 30px;
      }
    }
  }
}


.page-empty-text {
  display: block;
  margin: 20px auto;
  font-family: $font-main;
  font-size: 16px;
  font-weight: 400;
  color: $black;
}

.wipe-background {
  position: absolute;
  top: -100px;
  left: -100px;
  right: 0;
  z-index: 0;
  display: flex;
  justify-content: flex-end;
}

.tooltip-background {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.scroll-top {
  position: fixed;
  width: 52px;
  height: 52px;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  display: inline-block;
  background-color: #ffffff;
  border-radius: 50%;

  @media (min-width: 1024px) {
    right: 20px;
  }
}

@import "utils/layout";
@import "utils/thypography";

.number{
  font-weight: 600;
}
