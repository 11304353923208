@import "src/utils/variables";

.banner-partner{
  display: flex;
  flex-direction: column;
  padding:50px 25px;
  background: linear-gradient(86.74deg, #F2FFFE 0%, #D9FFFB 0.01%, #E4D9FF 99.99%);
  box-shadow: 0 0 10px rgba(62, 83, 114, 0.05);

  margin-top: 70px;

  @media (min-width: $width-sm) {
    margin-top: 30px;
    border-radius: 10px;
  }

  @media (min-width: $width-lg) {
    flex-direction: row;
    padding: 100px;

  }

  &__title{
    font-family: $font-main;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 130%;
    color: #1A1A1A;
    margin: 0;
    padding-right: 20px;

    &--insert{
     color: #3252B1;
    }

    @media (min-width: $width-lg) {
      font-size: 34px;
      line-height: 130%;

    }
  }

  &__text{
    font-family: $font-main;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #1A1A1A;
    margin: 10px 0 0;

    @media (min-width: $width-lg) {
      margin: 30px 0 0;
      font-size: 24px;
      line-height: 130%;
    }
  }

  &__box{
    width: 100%;

    &:nth-child(2){
      margin-top: 30px;

      & svg {
        width: 100%;
        height: 100%;
      }
    }

    @media (min-width: $width-lg) {
      &:nth-child(1){
        width: 40%;
        padding-right: 55px;
      }

      &:nth-child(2){
        width: 60%;
        margin-top: 0;


      }
    }
    & svg {
      width: 100%;
    }
  }
}
