@import "src/utils/mixins";
@import "src/utils/variables";

.letters-wrapper{
  margin-top: 50px;
  &__title{
    width: fit-content;
    position: relative;
    background-color: transparent;
    outline: none;
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 13px;
    color: $black;
    padding: 0 5px;
    display: flex;
    align-items: center;
    margin: 0 0 0 15px;

    border: none;
    border-radius: 10px 10px 0 0;
    height: 49px;
    cursor: pointer;

    @media (min-width: $width-md) {
      font-size: 16px;
      padding: 0 30px;
    }

    &:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      transition: .3s;
    }

    @media (min-width: $width-sm) {
      background-color: #ffffff;

      &:after{
        background: $backgroundGradient;
      }
    }
  }
}

.letters {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: 1px solid #F2F2F2;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(62, 83, 114, 0.03);

  background-color: $white;
  padding: 50px 35px;

}

.letters-slider{
  width: 100%;
  order: 0;
  @media (min-width: $width-lg){
    width: 90%;
    order: 1;
  }
}

.letters-next{
  order: 1;
  @media (min-width: $width-lg){
    width: 90%;
    order: 0;
  }
}
.letters-prev{
  order: 2;
  @media (min-width: $width-lg){
    width: 90%;
    order: 2;
  }
}

.letters-next,
.letters-prev {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: transparent;
  border: none;

  &:hover{
    cursor: pointer;
    background-color: #e7e7e7;
  }
}

.letters-item {
  width: 100%;
  text-align: center;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  padding: 10px;

  &__img {
    width: 100%;
    height: 100%;
  }

  &__wrap-img{
    min-height: 250px;
  }

  &__footer{
    padding: 5px 0;
    min-height: 50px;
  }

  &__title {
    font-family: $font-main;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $black;
    margin: 0;
  }

  &__date {
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: $light;
  }

  &__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #04BF8A;
    opacity: 0;
    transition: .3s;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    & .letters-item__bg {
      opacity: 1;
      transition: .3s;
      cursor: pointer;
    }
  }
}
