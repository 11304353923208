@import "src/utils/variables";

.review{
  display: flex;
  align-items: center;
  padding: 0 5px;

  @media (min-width: $width-sm) {
    padding: 0 30px 0 15px;
  }

  &__count{
    font-family: $font-main;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $black;
    margin: 0 5px;
  }

  &__text{
    font-family: $font-main;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: $notActive;
    margin: 0 5px 0;
    display: contents;
  }
}
