@import "src/utils/variables";

.advertising-banners{
  display: flex;
  flex-direction: column;

  &__box{
    margin: 5px 0 25px;
  }

  &__main{
    background: #FAFAFA;
    box-shadow: 0 0 10px rgba(62, 83, 114, 0.03);
    border-radius: 10px;
    padding: 52px 35px;

    display: flex;
    align-items: center;
  }

  &__text{
    font-family: $font-main;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: #808080;

    &--insert{
      color: #3252B1;
    }
  }
}

.default-banner{
  max-width: 367px;
  width: 100%;
  height: 193px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  padding: 30px;

  &__text{
    font-family: $font-main;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: #808080;
  }

  &__button{
    font-family: $font-main;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #3252B1;
    cursor: pointer;
    border: none;
    outline: none;
    border-bottom: 1px dashed #3252B1;
    padding: 0;
    background-color: transparent;
    width: 123px;
  }

  &__link{
    font-family: $font-main;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #808080;
    margin-top: 17px;
    display: flex;
    align-items: center;

    & svg{
      margin-right: 10px;
    }
  }
}

.default-banner-form{
  background: #FFFFFF;
  border-radius: 10px;
  padding: 30px;
  width: 320px;

  @media (min-width: $width-md) {
    width: 425px;
  }

  &__box{
    padding-bottom: 30px;
  }

}
