@import "src/utils/variables";

.loader{
  display: flex;
  justify-content: center;
  padding: 30px 0;
  &__text{
    font-family: $font-main;
    font-size: 14px;
    font-weight: bold;
    color: $black;
  }
}
