$font-main: Averta-CY, sans-serif;
$backgroundGradient: linear-gradient(84.29deg, #279FC2 0%, #3252B1 100%);
$backgroundGradientOutline: linear-gradient(84.29deg, #ffffff 0%, #ffffff 100%);
$backgroundGradientHover: linear-gradient(84.29deg, #238CAB 0%, #2D489B 100%);
$backgroundGradientTransparent: linear-gradient(84.29deg, rgba(35, 140, 171, 0) 0%, rgba(45, 72, 155, 0) 100%);
$bg: linear-gradient(89.09deg, #1DE2CE 0%, #390CA2 100%);

$white: #ffffff;
$black: #1A1A1A;
$light: #808080;
$dark: #3252B1;
$active: #04BF8A;
$notActive: #BFBFBF;

$width-xl: 1160px;
$width-lg: 990px;
$width-md: 768px;
$width-sm: 576px;
$width-s: 450px;
$width-extraS: 360px

