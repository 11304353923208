@import "src/utils/variables";

.tabs-mobile{
  padding: 70px 0;

  @media (min-width: $width-md) {
    padding: 50px 99px;
  }

  &__title{
    font-family: $font-main;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: #1A1A1A;
    margin: 0 0 30px;
    padding-right: 20px;
  }
}
