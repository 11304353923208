@import "src/utils/variables";

.tab-card-item {
  background: #FFFFFF;
  border: 1px solid #F2F2F2;
  box-shadow: 0 5px 10px rgba(62, 83, 114, 0.03);
  border-radius: 10px;
  padding: 27px;
  margin-bottom: 15px;

  &__header {
    display: flex;
    align-items: center;
  }

  &__icon {
    width: 50px;
  }

  &__wrapper-title {
    margin-left: 15px;
  }

  &__title {
    font-family: $font-main;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    color: #1A1A1A;
    margin: 0 7px 0 0;
  }

  &__text {
    font-family: $font-main;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    color: #1A1A1A;
    display: inline-block;
    word-wrap: anywhere;
    white-space: break-spaces;

    &-sm {
      font-family: $font-main;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 130%;
      color: #1A1A1A;
      margin: 0;
    }
  }

  &__button {
    border: none;
    outline: none;
    background-color: transparent;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #3252B1;
    cursor: pointer;
    margin-top: 30px;
    padding: 0;

    display: flex;
    align-items: center;

    & svg {
      display: inline-block;
      margin-left: 5px;
    }
  }
}

.tab-card-item-modal {
  width: 100%;
  background-color: #ffffff;
  &__header {
    padding: 25px 15px;

    @media (min-width: $width-sm) {
      padding: 30px;
    }
  }

  &__body {
    padding: 15px;

    @media (min-width: $width-sm) {
      padding: 14px 30px 30px;
    }
  }

  &__image{
    padding-top: 15px;
    & img {
      width: 100%;
    }
  }
}
