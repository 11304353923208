@import "src/utils/variables";

.confirmation-checkbox {
  display: flex;
  cursor: pointer;

  &__checked {
    width: 20px;
    padding-right: 20px;
  }

  &__text {
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 150%;
    color: $light;
    margin: 0;
  }
}
