@import "src/utils/variables";


.footer {
  background-color: #ffffff;

  &__inner {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 50px;

    @media (min-width: $width-lg) {
      flex-direction: row;
    }
  }

  &__box {
    width: 100%;
    margin-bottom: 20px;
    padding-top: 50px;

    &:nth-child(1) {
      order: 1;
    }

    &:nth-child(2) {
      order: 2;
    }

    &:nth-child(3) {
      order: 0;
    }

    @media (min-width: $width-s) {
      &:nth-child(1) {
        width: 50%;
        order: 1;
      }

      &:nth-child(2) {
        width: 50%;
        order: 2;
      }

      &:nth-child(3) {
        order: 0;
      }
    }

    @media (min-width: $width-lg) {
      &:nth-child(1) {
        width: 30%;
        order: 0;
      }

      &:nth-child(2) {
        width: 30%;
        order: 1;
      }

      &:nth-child(3) {
        width: 40%;
        order: 2;
      }
    }
  }

  &__name {
    font-family: $font-main;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    color: #1A1A1A;
    margin: 0;
  }

  &__inn {
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    text-transform: uppercase;
    margin: 10px 0;
  }

  &__link {
    display: block;
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;

    color: #3252B1;

    @media (min-width: $width-lg) {
      width: 50%;
    }
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 0;
    }

  }

  &__box-title{
    font-family: $font-main;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #1A1A1A;
    margin: 0 0 15px;
  }

  &__contact {
    font-family: $font-main;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;

    color: #1A1A1A;
    display: flex;
    align-items: center;
    margin: 0;

    & svg {
      margin-right: 12px;
    }
  }

  &__subtext {
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;

    color: #1A1A1A;
    display: inline-block;
    margin: 5px 0 10px 32px;
  }

  &__social {
    margin-top: 20px;
  }

  &__social-icon {
    margin-right: 10px;
  }

  &--mb15{
    margin-bottom: 15px;
  }

  &--mt30{
    margin-top: 30px;
  }
}

.subscribe {

  &__title {
    font-family: $font-main;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    color: #1A1A1A;
    margin: 0;

    @media (min-width: $width-lg) {
      width: 60%;
    }
  }

  &__text {
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;

    color: #1A1A1A;
    margin: 0;

    @media (min-width: $width-lg) {
      width: 60%;
    }

    &--link {
      font-family: $font-main;
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 15px;

      color: #3252B1;
    }
  }
}

.subscribe-form {
  display: flex;
  padding: 10px 0;

  @media (min-width: $width-s) {
    flex-direction: row;
    align-items: center;
  }

  & .button {
    padding: 0 15px;
    width: 130px;
    margin-left: 10px;
  }
}
