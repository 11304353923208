@import "src/utils/variables";
@import "src/utils/mixins";

.card-wrapper-gradient {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px rgba(62, 83, 114, 0.07);
  border-radius: 10px;
  padding: 1px;

  background: $backgroundGradient;

  &--hover{
    background: linear-gradient(84.29deg, rgba(39, 159, 194, 0) 0%, rgba(50, 82, 177, 0) 100%);

    &:hover {
      background: $backgroundGradient;
    }
  }

  &--active {
    background: $backgroundGradient;
    padding: 2px;
  }
}

.card-wrapper {
  padding: 5px 0;
  max-width: 764px;
  margin: 0 auto;
}

.card {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px rgba(62, 83, 114, 0.07);
  border-radius: 10px;
  padding: 20px 15px;
  background-color: $white;
  min-height: 230px;
  overflow: hidden;

  @media (min-width: $width-sm) {
    padding: 20px 30px;
  }

  &--free {
    min-height: auto;
  }

  &:hover {
    .card__tooltip {
      opacity: 1;
      transition: .5s;
      transform: translateX(0);

      font-family: $font-main;
      font-size: 14px;
      font-weight: 400;
      color: #390CA2;
    }

  }

  &__header {
    display: flex;
    flex-direction: column;
    position: relative;

    @media (min-width: $width-s) {
      flex-direction: row;
    }
  }

  &__header-box {
    flex-grow: 1;

    &:last-of-type {
      width: 80%;
      padding: 10px 0;
      @media (min-width: $width-s) {
        width: 20%;
        padding-left: 10px;
      }
    }

    &:empty {
      width: 0%;
      flex-grow: 0;
    }
  }

  &__title {
    font-family: $font-main;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    text-transform: uppercase;
    color: $black;
    margin: 0 0 10px;
    cursor: pointer;
  }

  &__subtitle{
    font-family: $font-main;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    color: #1A1A1A;
    display: inline-block;
    margin-right: 3px;
  }

  &__tooltip {
    font-family: $font-main;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #390CA2;
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    opacity: 1;

    @media (min-width: $width-lg) {
      right: 10px;
      opacity: 0;
      transform: translateX(-15px);
      transition: 0.5s;
      flex-grow: 1;
      display: inline-block;
    }
  }

  &__inn {
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: $light;
    margin: 10px 0 0;

    position: relative;

    @media (min-width: $width-sm) {
      font-size: 16px;
      line-height: 19px;
    }
  }

  &__text {
    display: inline-block;
    margin-right: 5px;
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: $black;
  }

  &__body {
    flex-grow: 1;
  }


  &__footer {
    display: flex;
    flex-direction: column;

    & .review {
      padding: 0;
    }
  }

  & .confirmation {
    width: 75px;

    & svg {
      margin-right: 5px;
      margin-left: 0;
    }
  }
}

.license {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;

  @media (min-width: $width-md) {
    max-width: 60%;
    margin-left: auto;
    justify-content: flex-end;
    margin-top: 0;
  }

  &__text {
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #808080;
    margin: 0 5px 0 0;

    @media (min-width: $width-s) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__item {
    font-family: $font-main;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: $black;
    border-bottom: 1px solid rgba(4, 191, 138, 0.3);
    transition: .5s;
    margin: 0 3px;
    display: inline-block;

    @media (min-width: $width-s) {
      font-size: 14px;
      line-height: 18px;
    }

    &:hover {
      border-bottom: 1px solid rgba(4, 191, 138, 0.97);
      transition: .5s;
      cursor: pointer;
    }
  }
}

.advanced-search {
  padding: 0;
  margin-right: auto;

  @media (min-width: $width-s) {
    padding: 0 15px;
  }

  &__button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    font-family: $font-main;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: $notActive;
    text-align: left;
    margin-top: 12px;

    @media (min-width: $width-s) {
      font-size: 16px;
      line-height: 19px;
    }

    &:hover {
      color: #3252B1;
    }
  }
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

.tooltip-accordion {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 15px;

  @media (min-width: $width-lg) {
    padding-bottom: 30px;
  }

  &__pre-title {
    max-width: 563px;
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: $black;
    margin: 10px auto 20px;

    @media (min-width: $width-lg) {
      margin: 30px auto 40px;
    }
  }

  &__button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;

    font-family: $font-main;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;

    color: #390CA2;
    padding: 0;

    &:hover {
      color: #3252B1;
    }

    & svg {
      margin-left: 15px;
      margin-top: 1px;
    }
  }

  &__icon {
    display: inline-block;
    margin-left: 10px;
    transform: rotate(180deg);
    transition: .5s;

    &--open {
      transform: rotate(0deg);
      transition: .5s;
    }
  }

  &__body {
    max-width: 764px;
    padding-top: 30px;
  }

  &__img {
    width: 100%;
    margin-bottom: 15px;
  }
}
