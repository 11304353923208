@import "src/utils/variables";

.badges{
  display: flex;
  flex-direction: column;
  padding-top: 30px;

  @media (min-width: $width-lg) {
    flex-direction: row;
  }
}

.badge{
  background: #FFFFFF;
  border: 1px solid #F2F2F2;
  box-shadow: 0 0 10px rgba(62, 83, 114, 0.05);
  border-radius: 10px;
  padding: 10px 20px;
  display: flex;
  align-items: center;

  @media (min-width: $width-sm) {
    padding: 30px 50px;
  }

  &:first-of-type{
    width: 100%;

    @media (min-width: $width-lg){
      max-width: 466px;
    }
  }

  &:last-of-type{
    width: 100%;
    margin-top: 30px;

    @media (min-width: $width-lg){
      max-width: 664px;
      margin-top: 0;
      margin-left: 30px;
    }
  }

  &__icon{
    width: 50px;
  }

  &__body{
    padding: 0 15px;
    @media (min-width: $width-sm) {
      padding: 0 20px;
    }
  }

  &__text{
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: $black;

    &--insert{
      font-weight: 500;
      padding-right: 10px;
    }
    & .tooltip-wrapper{
      top: 5px;
    }
  }
}
