@import "src/utils/variables";
.rating {
  display: flex;
  align-items: center;

  & svg{
    width: 12px;
    height: 11px;
  }

  &__count {

    font-family: $font-main;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $black;
    margin: 0 5px;
  }
}
