@import "src/utils/variables";

.form{
  max-width: 466px;
  width: 100%;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 30px 20px;

  &__box{
    padding: 12px 0;
    position: relative;
  }

}
