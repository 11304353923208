@import "variables";

.container{
  max-width: 1160px;
  width: 100%;
  margin: 0 auto;
  padding: 0 25px;

  @media (min-width: $width-sm) {
    padding: 0 15px;
  }

  @media (min-width: $width-lg) {
    padding: 0 15px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.col{
  width: 100%;
  padding: 15px;

  @media (min-width: 991px) {
    width: 50%;
  }
}

.p-15 {
  padding: 15px;
}

.py-10 {
  padding: 0 10px
}
.py-15 {
  padding: 0 15px
}

.px-15 {
  padding: 15px 0
}

.pt-10{padding-top: 10px}

.ml-10{margin-left: 10px}
.mr-10{margin-right: 10px}
.mt-20{margin-top: 20px}
