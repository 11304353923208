@import "src/utils/variables";

.confirmation{
  margin: 0 7px;
  width: 75px;

  & svg {
    margin: 0 4px;
    position: relative;
    z-index: 2;
  }

  &-tooltip-wrapper{
    position: relative;

    &:hover{
      .confirmation-tooltip{
        display: inline-block;
      }
    }
  }

  &-tooltip{
    position: absolute;
    top: -48px;
    left: 0;
    font-family: $font-main;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    color: $white;
    padding: 5px 10px;
    z-index: 10;
    background-color: $light;
    border-radius: 5px;
    display: none;
    transition: .5s;

    &::before{
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      left: 10px;
      bottom: -6px;

      background: $light;
      transform: rotate(-135deg);
      z-index: 5;
    }
  }
}
