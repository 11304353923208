@import "src/utils/variables";
.card-page{
  margin-bottom: 60px;

  @media (min-width: $width-lg) {
    margin-bottom: 100px;
  }
}

.utilizes{
  padding-bottom: 30px;
  min-height: 65vh;

  @media (min-width: $width-lg) {
    padding-bottom: 50px;
  }
}

.utilizes-card {
  width: 100%;
  padding: 31px 10px;
  background-color: $white;
  border: none;
  box-shadow: none;
  border-radius: 10px;
  margin-top: 20px;

  @media (min-width: $width-sm) {
    padding: 31px 50px;
    box-shadow: 0 0 10px rgba(62, 83, 114, 0.03);
    border: 1px solid #F2F2F2;
  }

  &__header {
    display: flex;
    flex-direction: column;

    @media (min-width: $width-s) {
      flex-direction: row;
    }
  }

  &__header-box {
    width: 100%;

    &:first-of-type {

      @media (min-width: $width-md) {
        width: 90%;
      }
    }

    &:last-of-type {
      width: 80%;
      margin: 10px 0;

      @media (min-width: $width-s) {
        width: 25%;
      }
      @media (min-width: $width-md) {
        width: 20%;
        margin: 0;
      }

      &:empty{
        width: 0;
      }
    }
  }

  &__header-head {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media (min-width: $width-sm) {
      flex-direction: row;
      align-items: center;
    }
  }


  &__logo {
    width: 100%;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-end;
    font-family: $font-main;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: $black;
    margin: 0;

    @media (min-width: $width-s) {
      align-items: center;
    }
  }

  &__url {
    font-family: $font-main;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $black;
    margin: 0;

    &:hover{
      color: #3252B1;
    }

    @media (min-width: $width-lg) {
      margin: 0 auto;
    }

    &--my-company {
      color: #3252B1;
      margin-left: 0;
      display: inline-flex;
      flex-direction: column;
      cursor: pointer;
      margin-top: 10px;


      &:after {
        content: '';
        width: 142px;
        height: 1.5px;
        background-color: rgba(50, 82, 177, 0.35);
      }

      @media (min-width: $width-sm) {
        margin-top: 0;
      }
    }
  }

  &__body {
    display: flex;
    padding-top: 20px;
    flex-direction: column-reverse;

    @media (min-width: $width-lg) {
      flex-direction: row;
    }
  }

  &__col {
    width: 100%;

    @media (min-width: $width-lg) {
      width: 50%;
    }
  }

  &__address {
    display: flex;
    justify-content: space-around;
    padding: 8px 20px;
    margin-top: 20px;
    background: rgba(32, 205, 202, 0.1);
    border-radius: 30px;

    max-width: 537px;
    width: 100%;

    & svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    &--text{
      font-family: $font-main;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
      color: $black;
      width: 90%;
      margin: 0;
    }
  }

  &__box {
    display: flex;
    flex-direction: column;
    padding-top: 16px;

    @media (min-width: $width-s) {
      flex-direction: row;
    }

    &--inner {

      &:nth-child(2) {
        flex-grow: 1;

        @media (min-width: $width-s) {
          padding-left: 20px;
        }
      }

    }
  }
}

.verify-component {
  display: flex;
  padding-top: 18px;

  &__text {
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: $notActive;
    display: inline-block;
    margin-right: 11px;
  }

  & svg {
    margin: 0 4px;
  }
}

.contact-item {
  display: flex;
  align-items: center;
  padding: 15px 0;
  min-height: 55px;

  &--nameTop {
    align-items: flex-start;
  }

  &__name {
    margin: 0;
    width: 55px;
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: #808080;
    white-space: nowrap;

    &--uppercase {
      text-transform: uppercase;
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    display: flex;
    flex-direction: column;

  }

  &__item {
    display: inline-flex;
    align-items: center;
  }

  &__text {
    margin: 0;
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: $black;
    white-space: pre-wrap;

    &--btn{
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      text-decoration: underline;
      color: $notActive;
    }
  }
}

.copy {
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}

.services {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin-top: 0;

  @media (min-width: $width-md) {
    padding: 10px 0 0 10px;
  }

  &__item {
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: $black;

    display: flex;
    align-items: center;
    padding: 17px 15px 0 0;

    &--notActive {
      color: $notActive;
    }

    & svg {
      margin-right: 10px;
    }
  }
}

.utilise-form-content {
  width: 96% !important;
  height: 300px;
  background-color: $white;
  border-radius: 10px;
  padding: 30px;
  margin: 100px 0 0;

  @media (min-width: $width-md) {
    width: 425px !important;
  }
}

.utilise-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__box {
    flex-grow: 1;
    padding-right: 0;

    &:nth-child(3) {
      padding-right: 0;
      flex-grow: 0;
      width: 100%;
      margin-left: auto;

      @media (min-width: $width-s) {
        width: 50%;
      }

      //@media (min-width: $width-md) {
      //  width: 25%;
      //}
    }

    @media (min-width: $width-md) {
      padding-right: 5px;
    }
  }

  .wrapper-input {
    margin: 10px 0;

    @media (min-width: $width-md) {
      margin-right: 10px;
    }
  }

  & .button {
    width: 156px;
    margin-left: auto;
    margin-top: 10px;
  }
}
