@import "src/utils/variables";

.bars{
  background: #FFFFFF;
  border: 1px solid #F2F2F2;
  box-shadow: 0 0 10px rgba(62, 83, 114, 0.05);
  border-radius: 10px;
  margin-top: 30px;
  padding-top: 30px;

  &__title{
    font-family: $font-main;
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 130%;
    text-align: center;
    color: $black;
    margin: 0 0 93px;
  }

  &__body{
    display: flex;
    align-items: flex-end;
  }

  &__text{
    margin-left: auto;
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 130%;
    color: $black;
    text-align: center;
    padding: 0 20px;

  }
}

.bar-item{
  flex-grow: 1;
  text-align: center;

  &__inner{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    border-bottom: 1px solid #D9D9D9;
    margin: 0 auto;
    width: 100%;
  }

  &__box{
    width: 69px;
    text-align: center;
    display: flex;
    flex-direction: column;
    &:nth-child(1){
      margin-right: 30px;
    }
  }

  &__bar{
    background: linear-gradient(25.91deg, #279FC2 0%, #3252B1 100%);
    box-shadow: 0 0 10px rgba(62, 83, 114, 0.05);
    border-radius: 10px 10px 0 0;

    &--notActive{
      background: #D9D9D9;
      box-shadow: 0 0 10px rgba(62, 83, 114, 0.05);
      border-radius: 10px 10px 0 0;
    }
  }

  &__textXNum{
    font-family: $font-main;
    font-style: normal;
    font-weight: 700;
    color: #3252B1;
    margin: 5px 0;


    @media (min-width: $width-md) {
      font-size: 34px;
      line-height: 130%;
    }
  }

  &__text{
    font-style: normal;
    font-weight: 300;
    text-align: center;
    color: #1A1A1A;
    font-size: 16px;
    line-height: 130%;

  }

  &__description{
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    color: #1A1A1A;
    margin: 15px 0;
    display: inline-block;
  }
}

.bars-mobile{
  display: flex;
  flex-direction: column;
  padding: 0 30px 30px;

  &__title{
    font-family: $font-main;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: #1A1A1A;
    margin: 0 0 30px;
  }
}

.bar-item-mobile{
  display: flex;
  flex-direction: row-reverse;
  align-items: center;


  &__description{
    font-family: $font-main;
    font-weight: 300;
    font-size: 16px;
    color: #1A1A1A;
    line-height: 130%;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
  }

  &__inner{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 90%;
    padding: 14px 0;
    border-left: 1px solid #D9D9D9;
  }

  &__box{
    &:nth-child(1){
      margin-right: 30px;
      display: flex;
    }
  }

  &__bar{
    height: 69px;
    background: linear-gradient(25.91deg, #279FC2 0%, #3252B1 100%);
    box-shadow: 0 0 10px rgba(62, 83, 114, 0.05);
    border-radius: 0 10px 10px 0;

    &--notActive{
      background: #D9D9D9;
      box-shadow: 0 0 10px rgba(62, 83, 114, 0.05);
      border-radius: 0 10px 10px 0;
    }
  }

  &__numbers{
    display: inline-flex;
    align-items: center;
    padding-left: 23px;
  }

  &__textXNum{
    font-family: $font-main;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 130%;
    color: #3252B1;
    margin: 5px 0;
  }

  &__text{
    font-style: normal;
    font-weight: 300;
    text-align: left;
    color: #1A1A1A;
    display: inline-block;

    font-size: 16px;
    line-height: 130%;
    padding-left: 15px;
  }
}
