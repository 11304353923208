@import "src/utils/variables";

.desktop-tabs {
  background: #F7F7F7;
  border: 1px solid #F2F2F2;
  box-shadow: 0 0 10px rgba(62, 83, 114, 0.05);
  border-radius: 10px;
  padding: 30px 50px;
  margin-top: 30px;

  &__title {
    text-align: center;
    margin: 0 auto;
    padding-bottom: 50px;
    font-family: $font-main;
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 130%;
    color: $black;

    @media (min-width: $width-lg) {
      width: 85%;
    }
  }
}

.desktop-tab-buttons-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  & .card-wrapper-gradient {
    height: 100%
  }
}

.desktop-tab-buttons {
  display: flex;
  justify-content: space-between;

  &__btn {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0 5px 10px rgba(62, 83, 114, 0.03);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    width: 141px;
    height: 100%;
    box-sizing: border-box;

    &:hover {
      cursor: pointer;
    }
  }

  &__text {
    font-family: $font-main;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: $black;
    text-align: center;

    &--insert {
      font-weight: 500;
      display: inline-block;
      padding-right: 5px;
    }
  }

  &__indicator {
    padding-top: 15px;
  }
}

.desktop-tab {
  background: #FFFFFF;
  border: 1px solid #F2F2F2;
  box-shadow: 0 5px 10px rgba(62, 83, 114, 0.03);
  border-radius: 10px;
  display: flex;
  padding: 30px;
  margin-top: 15px;

  & .box-1 {
    background-color: #E0F7FA;
  }

  & .box-2 {
    background-color: #E1F5FE;
  }

  & .box-3 {
    background-color: #E3F2FD;
  }

  & .box-4 {
    background-color: #E8EAF6;
  }

  & .box-5 {
    background-color: #EDE7F6;
  }

  & .box-6 {
    background-color: #F3E5F5;
  }

  & .box-7 {
    background-color: #FCE4EC;
  }

  &__box {
    box-shadow: 0px 5px 10px rgba(62, 83, 114, 0.03);
    border-radius: 10px;
    width: 50%;
    padding: 30px;

    &:last-of-type {
      padding-right: 0;
      box-shadow: none;
      display: flex;
      align-items: center;
    }

    & img {
      width: 100%;
    }
  }

  &__title {
    font-family: $font-main;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: $black;
    margin-top: 0;
    margin-bottom: 15px;
  }

  &__text {
    margin: 0;
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    color: $black;
  }
}
