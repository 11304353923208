@import "src/utils/variables";



.alert-box {
  position: absolute;
  top: -150px;
  left: -150px;
  width: 320px;
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
  padding: 10px;
  z-index: 100;
  border-radius: 10px;
  background: #FFFFFF;

  @media (min-width: $width-md) {
    width: 565px;
    height: 290px;
    left: -282px;
    padding: 25px;
  }

  &__icon{
    width: 70px;
    height: 70px;
  }

  &__body{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title{
    font-family: $font-main;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 200%;
    text-align: center;
    color: #1A1A1A;
    margin: 0;

    &:empty{
      height: 30px;
    }
  }

  &__text{
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    color: #1A1A1A;
    margin: 0;
  }

  &-error {
    background-color: #fdcdcd;
  }

  &-success {
    background: #FFFFFF;
  }

  &-info {
    background-color: #279FC2;
  }

  &__close {
    position: absolute;
    top: 30px;
    right: 30px;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    margin-left: 20px;
  }
}
