@import "src/utils/variables";

.pagination{
  list-style: none;
  display: flex;
  align-items: center;
  padding: 0;

  & li {
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 130%;
    color: $black;
    padding: 0 5px;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
  }

  & li.rc-pagination-item-active{
    color: $active;
    border: 1px solid $active;
  }
}
