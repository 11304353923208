@import "src/utils/variables";

.partner{
  &__title{
    font-family: $font-main;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 130%;
    color: $black;
    margin: 0 0 25px;

    @media (min-width: $width-sm) {
      font-size: 24px;
      line-height: 26px;
    }

    @media (min-width: $width-md) {
      font-size: 34px;
      line-height: 130%;
      margin: 0 0 30px;
    }

    &--insert{
      //display: block;
      color: #3252B1;
    }
  }
}

.presentation{
  display: flex;
  flex-direction: column;

  &:after{
    content: "";
    width: 100%;
    height: 3px;
    background: $bg;
  }

  &__inner{
    display: flex;
    flex-direction: column;
    padding: 25px 0 30px;

    @media (min-width: $width-md) {
     flex-direction: row;
    }
  }

  &__box{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    @media (min-width: $width-sm) {
      flex-direction: row;
      align-items: center;
    }

    &:nth-child(1){
      width: 100%;
      padding-bottom: 20px;

      @media (min-width: $width-md){
        max-width: 476px;
        padding: 0;
        border: none;

        .presentation__text{
          width: 60%;
        }
      }
    }

    &:nth-child(2) {
      padding-top: 10px;

      @media (min-width: $width-md){
        padding: 0;
      }
      &:before {
        @media (min-width: $width-md) {
          content: "";
          width: 1px;
          height: 48px;
          background-color: #D9D9D9;
          margin-right: 20px;
        }
      }
    }
  }

  &__text-big{
    font-family: $font-main;
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 130%;
    color: $black;
    margin: 0;
    white-space: nowrap;

    @media (max-width: 768px) {
      width: 105px;
    }

    &-num{
      font-weight: 600;

      &--mr{
        margin-right: 5px;
        display: inline-block;
      }
    }
  }

  &__text{
    margin: 5px 0 0;
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    color: $black;

    @media (min-width: $width-sm) {
      margin: 0;
      padding: 0 15px;
    }

    &--w50{
      @media (min-width: $width-s) {
        width: 50%;
      }
    }
  }
}

.offer{
  background: $bg;
  padding: 30px 0 50px;

  @media (min-width: $width-md) {
    padding: 70px 0;
  }

  &__inner{
    display: flex;
    flex-direction: column;
    height: 100%;

    @media (min-width: $width-lg) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__box{
    width: 100%;

    @media (min-width: $width-lg) {
      width: 50%;
    }

    &:nth-child(2){
      display: flex;
      justify-content: center;

      @media (min-width: $width-lg) {
        justify-content: flex-end;
      }
    }
  }

  &__icon{
    & svg {
      width: 40px;

      @media (min-width: $width-md) {
        width: 50px;
      }
    }
  }

  &__title{
    font-family: $font-main;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 130%;
    color: #FFFFFF;
    margin: 0 0 20px;

    @media (min-width: $width-md) {
      font-size: 34px;
      line-height: 130%;
      margin-bottom: 40px;
    }
  }

  &__list{
    padding: 0;
    margin: 0;
    list-style: none;

    @media (min-width: $width-lg) {
      padding-right: 20px;
    }
  }

  &__item{
    display: flex;
    align-items: center;
    padding: 10px 0;
  }

  &__text{
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    color: $white;
    padding: 0 20px;
    margin: 0;

    @media (min-width: $width-md) {
      font-size: 20px;
      line-height: 150%;
    }

    &--insert{
      font-weight: 600;
    }
  }

  &__subtext{
    font-family: $font-main;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
    margin: 30px 0 40px;

    @media (min-width: $width-lg) {
      margin: 105px 0 0;
      font-size: 20px;
      line-height: 22px;
      flex-grow: 1;
    }
  }
}

.prise{
  display: flex;
  justify-content: center;
  padding: 50px 0;
  margin: 0 auto;

  &__icon{
    width: 50px;
  }

  &__body{
    padding: 0 30px;
  }

  &__subtext{
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    color: $white;
    margin: 0;
  }

  &__text{
    font-family: $font-main;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    color: $white;
    text-transform: uppercase;
    margin: 0;
  }
}

