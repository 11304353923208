@import "src/utils/variables";

.accordion {
  width: 100%;
  border: 1px solid #E5E5E5;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;

  &--notActive{
    .accordion__button{
      background-color: #FFEFEF;
    }
  }

  &__button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 15px 10px;
    background: #E6F9F3;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    position: relative;

    @media (min-width: $width-md) {
      padding: 15px 30px;
    }

    &--inner{
      padding: 9px 10px;

      @media (min-width: $width-md) {
        padding: 15px 55px 15px 30px;
      }
    }

    &--icon{
      position: absolute;
      right: 25px;
      display: inline-block;
      margin-left: auto;
      transform: rotate(0deg);
      transition: .5s;
    }

    &--text{
      font-family: $font-main;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
      color: $black;

      text-align: left;
      margin-right: 25px;
      width: 80%;
    }


  }

  &__body{
    padding: 15px;
    background-color: $white;

    @media (min-width: $width-md) {
      padding: 30px;
    }
  }
}

.show-photo-button{
  border: 1px solid $active;
  border-radius: 30px;
  background-color: transparent;

  display: flex;
  align-items: center;
  padding: 5px 15px;
  font-family: $font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $active;

  margin-top: 5px;

  & svg {
    margin-right: 10px;
  }

}


