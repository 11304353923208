@import "src/utils/variables";

.back{
  padding-top: 20px;
  &__link{
    font-family: $font-main;
    font-size: 16px;
    font-weight: 500;
    color: #390CA2;
    cursor: pointer;

    display: flex;
    align-items: center;
  }


  & svg {
    display: inline-block;
    margin-right: 15px;
  }
}
