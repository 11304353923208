@import "src/utils/variables";

.header{
  position: relative;
  z-index: 3;
  padding: 22px 0 10px;

  @media (min-width: $width-s) {
    padding: 22px 0;
  }

  &__inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.logo{
  width: 225px;
  text-decoration: none;
}

.navigation{
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;

  &--mobile{
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
    z-index: 1000;
    transform: translateX(100%);
    transition: .3s;
    padding: 25px 5px;

    & .navigation__item{
      padding: 10px 0;
    }

    & .navigation__link{
      font-size: 18px;
      background-color: transparent;
      outline: none;
      border: none;
    }

  }

  &--open{
    transform: translateX(0%);
    transition: .3s;
  }

  &__close{
    position: absolute;
    right: 10px;
    top: 20px;
    z-index: 1001;
  }


  &__item{
    margin: 0 15px;
    display: flex;
    flex-direction: column;

    &:after {
      content: "";
      width: 100%;
      height: 2px;
      background: $backgroundGradientTransparent;
    }

    &:hover {
      &:after {
        content: "";
        width: 100%;
        height: 2px;
        background: $backgroundGradient;
      }
    }

    &--active{
      &:after {
        content: "";
        width: 100%;
        height: 2px;
        background: $backgroundGradient;
      }
    }
  }

  &__link{
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: $black;
    background-color: transparent;
    outline: none;
    border: none;
  }
}
