.modal{
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  //align-items: center;
  justify-content: center;
  z-index: 10;
  overflow: auto;

  &--hide{
    display: none;
  }

  &__overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    z-index: 10;
  }

  &__content{
    width: auto;
    height: 100vh;
    overflow: hidden;
    position: absolute;
    z-index: 11;
    display: flex;
    align-items: center;
    //margin-top: 30px;
  }

  &__close{
    position: fixed;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 10;
  }
}
