@import "variables";

.page-head-wrapper{
  padding: 0 15px;
}
.page-head{
  font-family: $font-main;
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  margin: 0;

  &:empty{
    display: none;
  }
}
