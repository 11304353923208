@import "src/utils/variables";

.rules{
  padding-bottom: 50px;
  &__title{
    font-family: $font-main;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    max-width: 768px;
    margin: 0 auto 50px;
    text-align: center;

    @media (min-width: $width-md) {
      font-size: 20px;
    }
  }

  &__subtitle{
    font-family: $font-main;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;

    @media (min-width: $width-md) {
      font-size: 16px;
    }

    &--block{
      display: block;
      margin-top: 20px;
    }
  }

  &__text{
    font-family: $font-main;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;

    @media (min-width: $width-md) {
      font-size: 16px;
    }

    & strong {
      display: inline-block;
      margin-right: 7px;
    }
  }
}

.rules-box{
  padding: 50px 0;


}


.rules-list{
  padding: 0;
  margin: 30px 0 0;
  list-style: none;
  max-width: 768px;

  &__item{
    display: flex;
    justify-content: space-between;

    &:nth-child(1){
      border-bottom: 1px solid #c2c2c2;
    }
  }

  &__text{
    font-family: $font-main;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin: 5px 0;
    width: 75%;

    @media (min-width: $width-md) {
      font-size: 16px;
    }

    &--price {
      width: 25%;
      font-weight: 600;
      display: inline-block;
      margin-right: 7px;
    }
  }
}
