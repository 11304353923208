@import "src/utils/variables";

.search{
  border-bottom: 1px solid #CCCCCC;
  padding-bottom: 20px;

  &__box{
    position: relative;
    max-width: 764px;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    box-sizing: border-box;

    &--last{
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }


    &--info {
      display: flex;
      padding: 15px 0 0;

      @media (min-width: $width-s) {
        padding: 0;
        position: absolute;
        right: -25px;
        top: 24px;
      }

      @media (min-width: $width-lg) {
        right: -40px;
      }

      &-text{
        font-family: $font-main;
        font-style: normal;
        font-weight: 300;
        font-size: 11px;
        line-height: 13px;
        color: #1A1A1A;
        width: 95%;
        margin:0 0 0 6px;
      }
    }
  }

  &__row{
    display: flex;
    flex-direction: column;

    & .button:nth-child(2){
      margin-top: 15px;
    }

    @media (min-width: $width-lg) {
      flex-direction: row-reverse;

      & .button:nth-child(2){
        margin-right: 15px;
        margin-top: 0;
      }
    }
  }

  &__clear-btn{
    background-color: transparent;
    border: none;
    outline: none;
    font-family: $font-main;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #390CA2;
    margin-top: 15px;

    &:hover{
      color: #3252B1;
      cursor: pointer;
    }

    @media (min-width: $width-lg) {
      margin-top: 0;
      margin-right: 15px;
    }
  }
}

.result-list{
  position: absolute;
  top: 53px;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: $white;
  border: 1px solid #F2F2F2;
  box-shadow: 0 0 10px rgba(62, 83, 114, 0.03);
  z-index: 10;

  &__item{
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 130%;
    color: $black;
    padding: 10px 20px;
    border-bottom: 1px solid $light;

    &:hover{
      background-color: rgba(50, 82, 177, 0.1);
      cursor: pointer;
    }
  }
}
