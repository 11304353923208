@import "src/utils/variables";

.feedback-form {
  background: $white;
  padding: 30px;
  width: 300px;
  height: 500px;
  overflow: auto;
  border-radius: 10px;

  @media (min-width: 475px) {
    width: 465px;
    height: auto;
  }

  @media (min-width: $width-sm) {
    width: 565px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-family: $font-main;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: $black;
    margin: 0;
  }

  &__box {
    padding: 10px 0;
    position: relative;
  }

  &__subtitle{
    font-family: $font-main;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $black;
    display: inline-block;
    margin-bottom: 15px;
  }

  &__text {
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    color: $black;
  }

  &__stars {
    background-color: #F7F7F7;
    border-radius: 10px;
    text-align: center;
    padding: 10px 0;
  }

  &__buttons{
    display: flex;
    padding-top: 10px;
  }
}

.cansel-button{
  width: 134px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  background: #F7F7F7;
  border-radius: 10px;
  height: 49px;
  font-family: $font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #BFBFBF;
}
