@import "src/utils/variables";

.feedback{
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;

  @media (min-width: $width-lg) {
    flex-direction: row;
    margin-bottom: 100px;
  }

  &__box{
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: $width-lg) {
      width: 50%;
    }

    &:last-of-type{
      align-items: flex-end;
    }

    & .button{
      margin-top: 10px;
    }
  }

  &__body{
    flex-grow: 1;
  }

  &__title{
    font-family: $font-main;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    color: #3252B1;
    padding-right: 0px;

    @media (min-width: $width-md) {
      font-size: 36px;
      line-height: 130%;
      padding-right: 70px;
    }
  }

  &__list{
    list-style: none;
    padding-left: 0;
    margin: 30px 0;

    @media (min-width: $width-lg) {
      margin: 50px 0;
    }
  }

  &__list-item{
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    color: #1A1A1A;
    display: flex;
    align-items: center;
    padding-bottom: 20px;

    @media (min-width: $width-lg) {
      padding-bottom: 30px;
    }

    &:before{
      content: '';
      width: 5px;
      height: 5px;
      background-color: #3252B1;
      display: inline-block;
      margin-right: 15px;
      border-radius: 50%;
    }

    @media (min-width: $width-md) {
      font-size: 20px;
      line-height: 25px;
    }
  }

  &__subtext{
    font-family: $font-main;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: #1A1A1A;
    margin: 0 0 40px;
    display: flex;
    flex-direction: column;

    @media (min-width: $width-lg) {
      width: 85%;
      display: initial;
      margin: 0;
    }

    &--insert{
      font-family: $font-main;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 150%;
      color: #3252B1;
      display: inline-block;
      margin-bottom: 0;

      & svg {
        margin-right: 5px;
      }

      @media (min-width: $width-lg) {
        display: initial;
        margin-left: 10px;
      }
    }
  }
}

.feedback-form-page{
  max-width: 565px;
  width: 100%;
  border: 1px solid #F2F2F2;
  box-shadow: 0 0 10px rgba(62, 83, 114, 0.03);
  border-radius: 10px;
  padding: 15px;
  background-color: #ffffff;

  &__label{
    font-family: $font-main;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #1A1A1A;
    margin-bottom: 15px;
    display: inline-block;

    &:after{
      content: "*";
      color: red;
      margin-left: 5px;
    }
  }
}

.radio-button{
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 15px;

  &__icon{
    width: 14px;
    height: 14px;
  }

  &__label{
    font-family: $font-main;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #1A1A1A;
    margin: 0 0 0 15px;
  }
}
