@import "src/utils/variables";

.offer-block {
  padding: 60px 0 80px;

  @media (min-width: $width-md) {
    padding: 70px 0 100px;
  }

  & strong {
    font-weight: 600;
  }

  &__title {
    font-family: $font-main;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 130%;
    color: $black;
    margin: 0 0 30px;

    display: flex;
    flex-direction: column;

    &--num {
      font-weight: 600;
    }

    @media (min-width: $width-md) {
      flex-direction: row;
      font-size: 34px;
      line-height: 130%;
    }

    &--insert {
      font-family: $font-main;
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 34px;
      color: #3252B1;
      margin: 7px 0;

      @media (min-width: $width-md) {
        font-size: 34px;
        line-height: 130%;
        margin: 0 7px;
      }
    }
  }

  &__wrap-text{
    padding-bottom: 10px;

    &--p0{
      padding: 0;
    }
  }

  &__text {
    font-family: $font-main;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #1A1A1A;
    margin: 10px 0;

    @media (min-width: $width-md) {
      margin: 0 0 10px;
      font-size: 20px;
      flex-direction: row;
    }

    &--insert {
      color: #3252B1;
      display: inline-block;
      font-weight: bold;
      margin: 5px 0;

      @media (min-width: $width-md) {
        margin: 0 7px;
      }
    }
  }
}

.offer-block-prise {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 20px;

  @media (min-width: $width-md) {
    padding: 30px 0 15px;
    flex-direction: row;
    align-items: center;
  }

  &__body {
    max-width: 663px;

    @media (min-width: $width-md) {
      padding-left: 20px;
    }
  }

  &__icon {
    & svg {
      width: 40px;
    }

    @media (min-width: $width-md) {
      width: 50px;
    }
  }


  &__text {
    font-family: $font-main;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    color: #1A1A1A;
    margin: 0;

    @media (min-width: $width-md) {
      font-size: 24px;
      line-height: 24px;
    }
  }
}

.offer-block-box {
  background: #E4D9FF;
  box-shadow: 0 5px 10px rgba(62, 83, 114, 0.03);
  border-radius: 10px;
  padding: 30px;
  margin-top: 70px;

  & strong {
    font-weight: 600;
  }

  &__subtitle{
    font-family: $font-main;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #1A1A1A;

    @media (min-width: $width-md) {
      font-size: 20px;
      line-height: 150%;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #1A1A1A;

    @media (min-width: $width-md) {
      font-size: 24px;
      line-height: 150%;
      margin: 30px 0;
    }

    &--num {
      font-family: $font-main;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
      color: #3252B1;
      margin-top: 7px;

      @media (min-width: $width-md) {
        font-size: 24px;
        line-height: 150%;
        margin-top: 0;
        margin-left: 7px;
      }
    }
  }

  &__text{
    font-family: $font-main;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #1A1A1A;

    @media (min-width: $width-md) {
      font-size: 20px;
      line-height: 150%;
    }
  }
}
