@import "src/utils/variables";

.tooltip-wrapper {
  position: relative;
  cursor: pointer;
  //line-height: 16px;

  &:hover {
    .tooltip {
      display: inline-block;
    }
  }
}

.tooltip {
  position: absolute;
  //top: -48px;
  left: 0;
  bottom: 25px;
  right: auto;
  font-family: $font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: $white;
  padding: 5px 10px;
  z-index: 10;
  background-color: $light;
  border-radius: 5px;
  display: none;
  transition: .5s;

  &::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: 10px;
    right: auto;
    bottom: -6px;
    background: $light;
    transform: rotate(-135deg);
    z-index: 5;
  }

  &--revert{
    left: auto;
    right: 0;

    &::before {
      left: auto;
      right: 10px;
    }
  }
}

