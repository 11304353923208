@import "src/utils/variables";

.wrapper-input{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;

  &__icon{
    width: 20px;
    height: 20px;
    position: absolute;
    right: 15px;
    transform: rotateX(180deg);
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;

    &--active{
      transform: rotateX(0deg);
      transition: 0.3s;
    }

    &:before{
      position: absolute;
      content: '';
      width: 1px;
      height: 25px;
      background-color: $notActive;
      left: -15px;
    }
  }
}

.input {
  width: 100%!important;
  background: $white;
  border: 1px solid #F2F2F2!important;
  box-shadow: 0 0 10px rgba(62, 83, 114, 0.03);
  border-radius: 10px!important;
  padding: 15px 20px;
  font-family: $font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px!important;
  line-height: 19px!important;
  color: $black;

  &::placeholder {
    font-family: $font-main;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: $notActive;
  }

  &--required::before{
    content: "*";
    position: absolute;
    right: 6px;
    top: 3px;
    color: red;
  }

  &:focus{
    outline: none;
  }
}

.output{
  width: 100%;
  min-height: 51px;
  background: $white;
  border: 1px solid #F2F2F2;
  box-shadow: 0 0 10px rgba(62, 83, 114, 0.03);
  border-radius: 10px;
  padding: 15px 20px;
  font-family: $font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $black;

  &--placeholder {
    font-family: $font-main;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: $notActive;
  }

  &__text{
    width: 75%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }

  &--required::before{
    content: "*";
    position: absolute;
    right: 6px;
    top: 3px;
    color: red;
  }
}

.textarea{
  width: 100%;
  height: 139px;
  resize: none;
  background: $white;
  border: 1px solid #F2F2F2;
  box-shadow: 0 0 10px rgba(62, 83, 114, 0.03);
  border-radius: 10px;
  padding: 15px 20px;
  font-family: $font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $black;

  &::placeholder {
    font-family: $font-main;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $notActive;
  }

  &--required::before{
    content: "*";
    position: absolute;
    right: 6px;
    top: 3px;
    color: red;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  background: $backgroundGradient;
  border-radius: 10px;
  height: 49px;

  font-family: $font-main;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: $white;

  transition: .3s;

  &:hover{
    background: $backgroundGradientHover;
    transition: .3s;
  }

  &__outline{
    background: $backgroundGradientOutline;
    border: 1px solid $dark;
    color: $dark;

    &:hover{
      background: $backgroundGradientOutline;
      opacity: 0.8;
    }
  }

  &--md {
    width: 155px;
  }

  &--xl {
    width: 100%;
  }

}

.selector{
  width: 100%;
  position: relative;

  & .output{display: block}
}

.selector-overlay{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 90;
}
.selector-list{
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  background-color: $white;
  padding: 0;
  margin: 0;
  list-style: none;
  z-index: 100;
  border: 1px solid #F2F2F2;
  box-shadow: 0 0 10px rgba(62, 83, 114, 0.03);

  &__item{
    padding: 10px 20px;
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 130%;
    color: $black;

    &--active{
      background-color: rgba(50, 82, 177, 0.1);
    }

    @media (min-width: $width-lg) {
      &:hover{
        background-color: rgba(50, 82, 177, 0.1);
        cursor: pointer;
      }
    }
  }
}
.emoji-wrapper{
  display: flex;
  justify-content: flex-end;
}
.emoji-box{
  position: absolute;
  bottom: 0;
}
