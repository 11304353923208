@import "src/utils/variables";

.infinity-scroll__info {
  font-family: $font-main;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 130%;
  color: $black;
  display: inline-block;
  padding: 0 20px;
}
