@import "src/utils/variables";

.gallery-in-modal {
  position: relative;

  & img {
    object-fit: cover;
    height: 90vh;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    background-color: transparent;
  }
}

.gallery-controls {
  display: flex;
  justify-content: center;
  width: 100%;

  &__prev,
  &__next {
    background-color: rgba(255, 255, 255, 0.42);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
  }
}
