@import "src/utils/variables";

.request-block{
  background: $bg;

  &__inner{
    display: flex;
    flex-direction: column;
    padding: 30px 0;

    @media (min-width: $width-md) {
      flex-direction: row;
      padding: 50px 0 100px;
    }
  }

  &__box{
    width: 100%;

    &:nth-child(2){
      display: flex;
      justify-content: center;
      margin: 30px 0;

      @media (min-width: $width-md){
        margin-top: 0;
        justify-content: flex-end;
      }
    }

    @media (min-width: $width-md) {
      width: 50%;
      padding: 0 15px;
    }
  }

  &__title{
    font-family: $font-main;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 24px;
    color: #FFFFFF;
    margin: 0 0 50px;

    @media (min-width: $width-md) {
      font-size: 34px;
      line-height: 130%;
    }
  }

  &__list{
    padding-left: 20px;
    margin: 0;
  }

  &__item{
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    list-style: disc;
    padding-bottom: 30px;

    @media (min-width: $width-md) {
      font-size: 20px;
      line-height: 150%;
    }
  }

  &__subtext{
    font-family: $font-main;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    color: #FFFFFF;


    @media (min-width: $width-md) {
      margin-top: 92px;
      font-size: 20px;
      line-height: 22px;
    }
  }
}

.request-form{
  max-width: 466px;
  width: 100%;
  padding: 30px 20px;
  background: #FFFFFF;
  border-radius: 10px;

  &__box{
    padding-bottom: 20px;
    position: relative;
  }
}
