@import "src/utils/variables";

.error-page{
  height: 80vh;
  &__title{
    font-family: $font-main;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 130%;
    color: #3252B1;
    padding-right: 0;
    text-align: center;
    margin: 0;

    @media (min-width: $width-md) {
      font-size: 124px;
      line-height: 130%;
    }
  }

  &__text{
    font-family: $font-main;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: $black;
    text-align: center;

    @media (min-width: $width-md) {
      font-size: 22px;
      line-height: 130%;
    }
  }
}
