@import "src/utils/variables";

.cover{
  display: flex;
  flex-direction: column;

  @media (min-width: $width-lg) {
    flex-direction: row;
    align-items: flex-end;
    height: 300px;
    position: relative;
  }

  &__box{
    width: 100%;

    @media (min-width: $width-lg) {
      width: 652px;
    }

    &:first-of-type{
      order:1;
      @media (min-width: $width-xl) {
        position: absolute;
      }
    }

    &:last-of-type{
      width: 100%;
      order: 0;
      @media (min-width: $width-lg) {
        width:38%;
        margin-left: auto;
        position: absolute;
        right: 0;
        bottom: -37px;
      }
      & svg {width: 100%}
    }
  }

  &__title{
    font-family: $font-main;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
    color: #1A1A1A;
    margin: 0;

    @media (min-width: $width-sm) {
      font-size: 34px;
      line-height: 42px;
    }

    @media (min-width: $width-md) {
      font-size: 42px;
      line-height: 52px;
    }

    &-num{
      font-weight: 600;
    }

  }

  &__title-inner{
    font-family: $font-main;
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 40px;

    color: #1A1A1A;
    margin: 0;
    text-transform: uppercase;

    &--insert{
      color: #3252B1;
    }
  }
}

.description{
  display: flex;
  padding: 30px 0 50px;

  @media (min-width: $width-md) {
    padding: 50px 0 70px;
  }

  &__box{
    display: flex;
    align-items: center;
    &:nth-child(1){
      width: 25%;
      padding-right: 13px;
      border-right: 1px solid $black;

      @media (min-width: $width-sm) {
        width: auto;
      }
    }

    &:nth-child(2){
      width: 75%;
      padding-left: 13px;

      @media (min-width: $width-xl) {
        width: 75%;
      }
    }
  }

  &__text{
    margin: 0;
    font-family: $font-main;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #1A1A1A;

    @media (min-width: $width-sm) {
      font-size: 16px;
      line-height: 130%;
    }

    @media (min-width: $width-md) {
      font-size: 18px;
      line-height: 130%;
    }
  }
}

.cover-links{
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;

  & .button {
    max-width: 290px;
    &:nth-child(1){
      margin-bottom: 20px;

      @media (min-width: $width-lg) {
        margin-right: 30px;
      }
    }

  }

  @media (min-width: $width-md) {
    flex-direction: row;
  }
  @media (min-width: $width-lg) {
    max-width: 59%;
    padding-bottom: 200px;
  }
}

.cover-btn{
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  margin-right: 10px;
  font-weight: 400;
}

.users-review{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding-top: 70px;

  @media (min-width: $width-md) {
    padding-top: 70px;
  }

  &__title{
    font-family: $font-main;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: $black;
    margin: 0 0 30px;

    @media (min-width: $width-md) {
      font-size: 34px;
      line-height: 42px;
      max-width: 763px;
      margin: 0 0 50px;
    }
  }
}

.users-review-card{
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 10px rgba(62, 83, 114, 0.03);
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 30px;
  height: 200px;

  &--full{
    height: 100%;
  }

  @media (min-width: $width-md) {
      width: 49%;
  }

  &__title{
    font-family: $font-main;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    color: #1A1A1A;
    margin: 0;


    @media (min-width: $width-sm) {
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
    }
  }

  &__date{
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 130%;
    color: #BFBFBF;
    display: inline-block;
    margin-top: 10px;
  }

  &__text{
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 130%;
    color: #1A1A1A;
    margin: 15px 0 5px;

    &--hidden {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &__more{
    font-family: $font-main;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #BFBFBF;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    margin: 0;

    & svg {
      margin: 3px 0 0 5px;
    }
  }
}

.targets{
  background: $bg;
  padding: 53px 0 23px;

  @media (min-width: $width-md) {
    padding: 53px 0
  }

  &__inner{
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: $width-lg) {
      flex-direction: row;
    }
  }

  &__col{
    width: 100%;

    @media (min-width: $width-lg) {
      width: 33.3%;
    }
  }
}

.targets-item{
  display: flex;
  padding:0 0 30px;


  @media (min-width: $width-lg) {
    padding: 10px 0;
  }

  @media (min-width: $width-md) {
    padding: 0;
  }

  &__icon{
    width: 40px;
    height: 40px;
    text-align: center;

    @media (min-width: $width-sm) {
      width: 60px;
      height: 60px;
    }
  }

  &__body{
    padding: 0 20px;
    width: 70%;

    @media (min-width: $width-md) {
      width: 85%;
    }

    @media (min-width: $width-lg) {
      width: auto;
      padding-left: 30px;
    }
  }

  &__title{
    font-family: $font-main;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: $white;
    margin: 0;

    @media (min-width: $width-md) {
      font-size: 34px;
      line-height: 42px;
    }
  }

  &__text{
    font-family: $font-main;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: $white;
    margin: 10px 0 0;

    @media (min-width: $width-md) {
      font-size: 22px;
      line-height: 130%;
    }
  }
}

.review-form{
  width: 100%;
  padding: 30px 0 70px;

  @media (min-width: $width-md) {
    padding: 70px 0;
    width: 50%;
  }

  &__title{
    font-family: $font-main;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: $black;
    margin: 10px 0 15px;

    @media (min-width: $width-sm) {
      font-size: 20px;
      line-height: 22px;
    }

    @media (min-width: $width-md) {
      font-size: 34px;
      line-height: 130%;
    }
  }

  &__body{
    display: flex;
    flex-direction: column;
    @media (min-width: $width-s) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__text{
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    color: #1A1A1A;
    display: block;
    margin-right: 18px;
    flex-grow: 1;

    padding-bottom: 30px;

    @media (min-width: $width-md) {
      padding: 0;
      font-size: 22px;
      line-height: 130%;
    }
  }

  & .button{
    width: 230px;
    padding: 0 5px;
    & svg{
      margin-right: 15px;
    }
  }
}

.form{
  display: flex;
  flex-direction: column;

  & .button {width: 100%;}

}
