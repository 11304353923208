@import "src/utils/variables";

.tabs {
  @media (min-width: $width-md) {
    margin-top: 50px;
  }
}


.tabs-content {
  border-radius: 10px;
  padding: 0;

  @media (min-width: $width-sm) {
    background: #FFFFFF;
    padding: 50px 35px;
    border: 1px solid #F2F2F2;
    box-shadow: 0 0 10px rgba(62, 83, 114, 0.03);
  }
}

.tabs-buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;

  @media (min-width: $width-sm) {
    padding: 0 15px;
  }

  &__btn {
    width: fit-content;
    position: relative;
    background-color: transparent;
    outline: none;
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 13px;
    color: $black;
    padding: 0 5px;
    display: flex;
    align-items: center;

    border: none;
    height: 49px;
    cursor: pointer;

    @media (min-width: $width-md) {
      font-size: 16px;
      padding: 0 30px;
    }

    &:after {
      position: absolute;
      content: '';
      background: none;
      width: 0;
      height: 2px;
      bottom: 0;
      left: 0;
      transition: .3s;
    }

    &--active {
      font-weight: 400;
      background-color: transparent;
      border-radius: 10px 10px 0 0;

      &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        transition: .3s;
      }

      @media (min-width: $width-sm) {
        background-color: #ffffff;

        &:after{
          background: $backgroundGradient;
        }
      }

    }
  }

  &__count {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    margin-left: 5px;
    font-family: $font-main;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #808080;
    background-color: #F2F2F2;
    display: flex;
    align-items: center;
    justify-content: center;

  }
}

.utilizes-list {
  list-style: none;
  padding: 0;
  margin: 0;

  &__item {
    display: flex;
    padding-bottom: 20px;
  }

  &__name {
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 130%;
    color: $light;
    width: 40%;
  }

  &__empty-text{
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 130%;
    color: $light;
    text-align: center;
  }

  &__content {
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: $black;
    width: 60%;
    display: flex;
    flex-direction: column;

    &--span{
      display: inline-block;

      &:nth-child(1){
        margin-right: 3px;
      }
    }
  }

  &__status {
    display: inline-block;
    width: fit-content;
    left: 0;
    bottom: 0;
    padding: 10px 20px;

    border-radius: 30px;
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: $black;
    margin-top: 5px;

    &--active {
      background-color: #E6F9F3;
    }

    &--notActive {
      background-color: #FFEFEF;
    }
  }
}

.utilizes-location{
  &__name{
    font-family: $font-main;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: #000000;
  }
}

.utilizes-location-inner {
  &__header {
    padding-bottom: 10px;

    .utilizes-location-inner__row {
      padding: 0;
      border: none;
    }
  }

  &__row {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    border-bottom: 1px solid #E5E5E5;

    @media (min-width: $width-md) {
      flex-direction: row;
    }
  }

  &__box {
    &:nth-child(1) {
      width: 100%;

      @media (min-width: $width-md) {
        width: 60%;
      }
    }

    &:nth-child(2) {
      width: 100%;
      padding: 7px 0;

      .utilizes-location-inner__content {
        text-align: left;
      }

      @media (min-width: $width-md) {
        width: 21%;
        padding: 0 7px;

        .utilizes-location-inner__content {
          text-align: center;
        }
      }
    }

    &:nth-child(3) {
      width: 100%;
      @media (min-width: $width-md) {
        width: 19%;
      }
    }
  }

  &__name {
    font-family: $font-main;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    color: $black;

    @media (min-width: $width-md) {
     // min-height: 33px;
      text-align: center;
      border-bottom: 1px solid #E5E5E5;
      padding-bottom: 10px;
    }
  }

  &__number {
    font-family: $font-main;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: $black;
  }

  &__content {
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: $black;
    margin: 5px 0;
  }

  &__loader {
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: $black;
    display: inline-block;
    padding: 5px;
  }
}

.leave-feedback {
  display: flex;
  background-color: #F7F7F7;
  box-shadow: 0 0 10px rgba(62, 83, 114, 0.03);
  border-radius: 10px;
  padding: 26px;


  flex-direction: column;

  @media (min-width: $width-md) {
    flex-direction: row;
  }

  &__box {
    width: 100%;
    padding: 0;
    display: flex;

    @media (min-width: $width-s) {
      padding: 0 20px;
    }

    &:nth-child(1) {
      align-items: center;
      justify-content: center;

      @media (min-width: $width-md) {
        border-right: 1px solid #E5E5E5;
      }

      @media (min-width: $width-lg) {

        width: 30%;
        margin-left: auto;
      }
    }

    &:nth-child(2) {
      flex-direction: column;
      align-items: center;
      @media (min-width: $width-lg) {
        width: 30%;
        margin-right: auto;
      }
    }
  }

  &__count {
    font-family: $font-main;
    font-style: normal;
    font-weight: 500;
    font-size: 72px;
    line-height: 84px;
    color: $black;
    margin-right: 20px;
  }

  &__text-sm {
    font-family: $font-main;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: $light;
  }

  &__text-form {
    font-family: $font-main;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    color: $light;
  }
}

.review-list {
  display: flex;
  flex-wrap: wrap;
}

.review-list-item {
  width: 100%;
  padding-top: 30px;

  @media (min-width: $width-md) {
    width: 50%;
    padding-right: 30px;

    &:nth-child(2n) {
      padding-right: 0;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-family: $font-main;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: $black;
    margin: 0 0 10px;
  }

  &__date {
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    text-transform: uppercase;
    color: $notActive;
  }

  &__content {
    font-family: $font-main;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 130%;
    color: $light;
  }
}


.license-list {
  padding: 0;
}
